<template>
    <div class="permission-manager">
        <b-card no-body class="shadow py-3 px-4">
            <b-card-header>
                <span class="h2 font-weight-bold align-self-start">Gestión de contenido Helexium
                </span>
            </b-card-header>
            <div class="text-white d-flex flex-row justify-content-between p-2 pt-4">
                <div class=" col-10 d-flex flex-row">
                    <span class="h4 align-self-start font-weight-bold mb-0 mr-4">Id Json:
                        <p class=" font-weight-normal"> {{ data.id }}</p>
                    </span>
                    <span class="h4 align-self-start font-weight-bold">Fecha registro:
                        <p class=" font-weight-normal"> {{ data.fechaRegistro | timeSince }}</p>
                    </span>
                </div>
                <vs-tooltip>
                    <vs-button style="width: 30px; height: 28px;" circle icon relief
                        @click="activeModal = !activeModal">
                        <i class="fa-solid fa-exclamation"></i>
                    </vs-button>
                    <template #tooltip>
                        Más Información
                    </template>
                </vs-tooltip>
                <!-- TOOLTIP ESTRUCTURA (SE PUEDE MEJORAR SIENDO UN COMPONENTE DE VUE) -->
            </div>
            <vs-dialog v-model="activeModal">
                <div class="card shadow hierarchy-explanation">
                    <div class="card-header bg-secondary text-white">
                        <span class="h4 mb-0 font-weight-bold text-white text-uppercase">Explicación de
                            Jerarquías</span>
                    </div>
                    <div class="p-3">
                        <div v-for="(explanation, level, index) in hierarchyExplanations" :key="level"
                            class="hierarchy-level card bg-dark p-3 my-3" :style="{ '--index': index }">
                            <span class="h5 font-weight-bold">{{ explanation.title }}</span>
                            <p>{{ explanation.description }}</p>
                            <p><strong>Ejemplo:</strong> {{ explanation.example }}</p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <vs-button @click="activeModal = false" danger>
                            Cancelar
                        </vs-button>
                        <vs-button @click="activeModal = false" info>
                            Aceptar
                        </vs-button>
                    </div>
                </div>
            </vs-dialog>
            <b-card-body>
                <div class="gestionar-layout">
                    <!-- Panel izquierdo (Categorías) -->
                    <div class="left-panel" :class="{ 'mobile-hidden': isMobile && categoria !== null }">
                        <draggable v-model="data.registroJson.data" :move="checkMove" @start="drag = true"
                            @end="drag = false" v-bind="dragOptions" handle=".handle">
                            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                <div v-for="group in data.registroJson.data" :key="group.groupId" class="category-item"
                                    :class="{ 'active text-uppercase font-weight-bold': categoria === group.groupId }"
                                    @click="selectCategory(group.groupId)">
                                    <div class="d-flex align-items-center justify-content-between p-2 handle">
                                        <div>
                                            <b-img :src="group.icono" width="24" height="24" :alt="group.titulo"
                                                class="mr-2"></b-img>
                                            <span>{{ group.titulo }}</span>
                                        </div>
                                        <div class="d-flex justify-content-between col-2 p-0 m-0">
                                            <i class="fa-solid fa-pen " v-b-modal.modal-category hover
                                                @click="modalCategory(group, 'newCategory', null, null, 'EDITAR CATEGORÍA', true)"></i>
                                            <i class="fa-solid fa-x " @click.stop="removeCategory(group.groupId)"></i>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </draggable>
                        <div class=" d-flex justify-content-end p-2">
                            <vs-button success v-b-modal.modal-category
                                @click="modalCategory(null, null, null, null, 'AÑADIR CATEGORÍA', false)">
                                <i class="fa-solid fa-plus mr-2"></i> Añadir categoría
                            </vs-button>
                        </div>
                    </div>

                    <!-- Panel derecho (Contenido de la categoría) -->
                    <div class="right-panel" :class="{ 'mobile-full': isMobile && categoria !== null }">
                        <div v-if="categoria !== null">
                            <div class="category-header">
                                <i v-if="isMobile" class="fa-solid fa-arrow-left mr-3" @click="deselectCategory"></i>
                                <span class=" h2 text-uppercase">{{ selectedCategoryTitle }}</span>
                            </div>
                            <div v-for="group in data.registroJson.data" :key="group.groupId">
                                <div v-if="group.groupId === categoria">
                                    <draggable v-model="group.subGrupo" @start="drag = true" @end="drag = false"
                                        :move="checkMove" v-bind="dragOptions" handle=".handle">
                                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                            <div v-for="(subGroup, subGroupIndex) in group.subGrupo" :key="subGroup.id">
                                                <b-card no-body class="mb-3">
                                                    <b-card-header
                                                        v-b-toggle="'collapse-' + manipularTitulos(subGroup.titulo, subGroupIndex)"
                                                        class="d-flex justify-content-between align-items-center"
                                                        @click="colapsar(subGroupIndex)">
                                                        <div>
                                                            <i class="fa fa-align-justify handle mr-2"></i>
                                                            <span class="h5">
                                                                {{ subGroup.titulo === 'Super Admin' &&
                                                                    usuario.rol.nivel !== 4 ? 'Admin' : subGroup.titulo }}
                                                            </span>
                                                        </div>
                                                        <div class="col-8 col-md-2 d-flex justify-content-between">
                                                            <i class="fa-solid fa-pen" v-b-modal.modal-aplicacion
                                                                @click.stop="modalCategory(group, 'subGrupo', subGroup, 'newAplication', 'EDITAR APLICACIÓN', true)"></i>
                                                            <i class="fa-solid fa-x"
                                                                @click.stop="removeSubGroup(group, subGroup)"></i>
                                                            <i
                                                                :class="colapsado[subGroupIndex] ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                                                        </div>
                                                    </b-card-header>
                                                    <b-collapse
                                                        :id="'collapse-' + manipularTitulos(subGroup.titulo, subGroupIndex)"
                                                        class="mt-2">
                                                        <draggable v-model="subGroup.items" @start="drag = true"
                                                            @end="drag = false" :move="checkMove" v-bind="dragOptions"
                                                            handle=".handle">
                                                            <transition-group type="transition"
                                                                :name="!drag ? 'flip-list' : null">
                                                                <div v-for="(item, itemIndex) in subGroup.items"
                                                                    :key="item.id">
                                                                    <b-card v-if="validarSuperAdmin(item.superAdmin)"
                                                                        class="mb-2">
                                                                        <b-list-group flush>
                                                                            <b-list-group-item
                                                                                class="d-flex justify-content-between align-items-center">
                                                                                <div>
                                                                                    <i
                                                                                        class="fa fa-align-justify handle mr-2"></i>
                                                                                    <span>{{ item.titulo }}</span>
                                                                                </div>
                                                                                <div
                                                                                    class="col-10 col-md-2 d-flex justify-content-end">
                                                                                    <i class="fa-solid fa-pen mr-4"
                                                                                        v-b-modal.modal-vista
                                                                                        @click.stop="modalCategory(subGroup, 'vista', item, 'newItem', 'EDITAR VISTA', true)"></i>
                                                                                    <i class="fa-solid fa-x"
                                                                                        @click="removeItem(subGroup, item)"></i>
                                                                                </div>
                                                                            </b-list-group-item>

                                                                        </b-list-group>
                                                                        <div v-if="item.subItems"
                                                                            class=" border-bottom border-left border-right mx-4 px-4">
                                                                            <b-button
                                                                                v-b-toggle="'collapse-' + manipularTitulos(item.titulo, itemIndex)"
                                                                                size="sm" class="mt-2 mb-2">
                                                                                Ver sub-vistas
                                                                            </b-button>
                                                                            <b-collapse
                                                                                :id="'collapse-' + manipularTitulos(item.titulo, itemIndex)"
                                                                                class="ml-4 mt-1 mb-2 ">
                                                                                <draggable v-model="item.subItems"
                                                                                    @start="drag = true"
                                                                                    @end="drag = false"
                                                                                    :move="checkMove"
                                                                                    v-bind="dragOptions"
                                                                                    handle=".handle">
                                                                                    <transition-group type="transition"
                                                                                        :name="!drag ? 'flip-list' : null">
                                                                                        <b-list-group-item
                                                                                            v-for="subItem in item.subItems"
                                                                                            :key="subItem.id"
                                                                                            class="border-left  d-flex justify-content-between align-items-center ">
                                                                                            <div>

                                                                                                <i
                                                                                                    class="fa fa-align-justify handle mr-2"></i>
                                                                                                <span>{{ subItem.titulo
                                                                                                    }}</span>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-2 d-flex justify-content-end">
                                                                                                <i class="fa-solid fa-pen mr-4"
                                                                                                    v-b-modal.modal-subVista
                                                                                                    @click.stop="modalCategory(item, 'subVista', subItem, 'newSubItem', 'EDITAR SUB-VISTA', true)"></i>
                                                                                                <i class="fa-solid fa-x"
                                                                                                    @click="removeSubItem(item, subItem)"></i>
                                                                                            </div>
                                                                                        </b-list-group-item>
                                                                                    </transition-group>
                                                                                </draggable>
                                                                                <div
                                                                                    class=" d-flex justify-content-end">
                                                                                    <vs-button success
                                                                                        v-b-modal.modal-subVista
                                                                                        @click.stop="modalCategory(item, 'subVista', null, null, 'AÑADIR SUB-VISTA', false)">
                                                                                        <i
                                                                                            class="fa-solid fa-plus mr-2"></i>
                                                                                        Añadir sub vista
                                                                                    </vs-button>
                                                                                </div>
                                                                            </b-collapse>
                                                                        </div>
                                                                    </b-card>
                                                                </div>
                                                            </transition-group>
                                                        </draggable>
                                                        <div class=" d-flex justify-content-end p-2">
                                                            <vs-button success v-b-modal.modal-vista
                                                                @click.stop="modalCategory(subGroup, 'vista', null, null, 'AÑADIR VISTA', false)">
                                                                <i class="fa-solid fa-plus mr-2"></i> Añadir vista
                                                            </vs-button>
                                                        </div>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </transition-group>
                                    </draggable>
                                    <div class=" d-flex justify-content-end p-2">
                                        <vs-button success v-b-modal.modal-aplicacion
                                            @click.stop="modalCategory(group, 'subGrupo', null, null, 'AÑADIR APLICACIÓN', false)">
                                            <i class="fa-solid fa-plus mr-2"></i> Añadir aplicación
                                        </vs-button>
                                    </div>
                                </div>
                                <div v-else class="no-selection">

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="d-flex justify-content-end mt-4">
                    <b-button variant="success" class="mr-2" @click="saveData">
                        <i class="fas fa-save mr-1"></i> Guardar Información
                    </b-button>
                    <b-button variant="danger" @click="cancelar">
                        <i class="fas fa-times mr-1"></i> Cancelar
                    </b-button>
                </div>
            </b-card-body>
        </b-card>

        <!-- MODALES -->
        <b-modal id="modal-category" :title="modalTitle" centered cancel-title='Cancelar'
            @ok="addCategory(newCategory, isEdit)">
            <b-form-group label="Titulo" label-for="Titulo-input" invalid-feedback="Titulo es requerido">
                <b-form-input id="Titulo-input" v-model="newCategory.titulo" required></b-form-input>
            </b-form-group>
            <b-form-group label="Ruta" label-for="ruta-input" invalid-feedback="Ruta requerida">
                <b-form-input id="ruta-input" v-model="newCategory.ruta" required></b-form-input>
            </b-form-group>
            <b-form-group label="Icono" label-for="icono-input" invalid-feedback="Icono requerida">
                <b-form-input id="icono-input" v-model="newCategory.icono" required></b-form-input>
            </b-form-group>

        </b-modal>
        <b-modal id="modal-aplicacion" :title="modalTitle" centered cancel-title='Cancelar'
            @ok="addSubGroup(subGrupo, newAplication, isEdit)">
            <!-- <p>1</p>
            {{ subGrupo }}
            <p>2</p>
            {{ newAplication }} -->
            <b-form-group label="Titulo" label-for="Titulo-input" invalid-feedback="Titulo es requerido">
                <b-form-input id="Titulo-input" v-model="newAplication.titulo" required></b-form-input>
            </b-form-group>
            <b-form-group label="Ruta" label-for="ruta-input" invalid-feedback="Ruta requerida">
                <b-form-input id="ruta-input" v-model="newAplication.ruta" required></b-form-input>
            </b-form-group>
            <b-form-group label="Icono" label-for="icono-input" invalid-feedback="Icono requerida">
                <b-form-input id="icono-input" v-model="newAplication.icono" required></b-form-input>
            </b-form-group>
            <b-form-group label="Nivel" label-for="nivel-input" invalid-feedback="Nivel requerido">
                <b-form-input id="nivel-input" v-model="newAplication.nivel" required></b-form-input>
            </b-form-group>
        </b-modal>
        <b-modal id="modal-vista" :title="modalTitle" centered cancel-title='Cancelar'
            @ok="addItem(vista, newItem, isEdit)">
            <b-form-group label="Titulo" label-for="Titulo-input" invalid-feedback="Titulo es requerido">
                <b-form-input id="Titulo-input" v-model="newItem.titulo" required></b-form-input>
            </b-form-group>
            <b-form-group label="Ruta" label-for="ruta-input" invalid-feedback="Ruta requerida">
                <b-form-input id="ruta-input" v-model="newItem.ruta" required></b-form-input>
            </b-form-group>
            <b-form-group label="Icono" label-for="icono-input" invalid-feedback="Icono requerida">
                <b-form-input id="icono-input" v-model="newItem.icono" required></b-form-input>
            </b-form-group>
            <b-form-group label="Nivel" label-for="nivel-input" invalid-feedback="Nivel requerido">
                <b-form-input id="nivel-input" v-model="newItem.nivel" type="text" required></b-form-input>
            </b-form-group>
            <b-form-group label-for="nivel-input">
                ¿Será vista super admin?
                <b-form-checkbox id="superAdmin-input" v-model="newItem.superAdmin" name="superAdmin-input" :value=true
                    :unchecked-value=false>
                </b-form-checkbox>
            </b-form-group>
            <b-form-group label-for="nivel-input">
                ¿Añadir sub vistas?
                <b-form-checkbox id="subVistas-input" v-model="isSubItemsArray" name="subVistas-input" :value=true
                    :unchecked-value=false>
                </b-form-checkbox>
            </b-form-group>
        </b-modal>
        <b-modal id="modal-subVista" :title="modalTitle" centered cancel-title='Cancelar'
            @ok="addSubItem(subVista, newSubItem, isEdit)">
            <b-form-group label="Titulo" label-for="Titulo-input" invalid-feedback="Titulo es requerido">
                <b-form-input id="Titulo-input" v-model="newSubItem.titulo" required></b-form-input>
            </b-form-group>
            <b-form-group label="Ruta" label-for="ruta-input" invalid-feedback="Ruta requerida">
                <b-form-input id="ruta-input" v-model="newSubItem.ruta" required></b-form-input>
            </b-form-group>
            <b-form-group label="Icono" label-for="icono-input" invalid-feedback="Icono requerida">
                <b-form-input id="icono-input" v-model="newSubItem.icono" required></b-form-input>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
// import groupsData from "@/components/partials/backend/HeaderStyle/groupsV2.json";
import Swal from "sweetalert2";

import draggable from "vuedraggable";

import moment from "moment/moment";
import moment_timezone from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
    data() {
        return {
            data: '',
            colapsado: {},
            usuario: '',
            drag: false,
            categoria: '',
            newCategory: {
                groupId: 0,
                titulo: '',
                ruta: '',
                icono: '',
                subGrupo: []
            },
            newAplication: {
                id: 0,
                titulo: '',
                icono: '',
                ruta: '',
                nivel: '4',
                items: []
            },
            newItem: {
                id: 0,
                titulo: '',
                icono: '',
                ruta: '',
                nivel: '4',
                superAdmin: null,
                subItems: null
            },
            newSubItem: {
                id: 0,
                titulo: '',
                icono: '',
                ruta: '',
            },
            subGrupo: null,
            vista: null,
            subVista: null,
            activeModal: null,
            hierarchyExplanations: {
                category: {
                    title: 'Categoría',
                    description: 'El nivel más alto de la jerarquía. Representa una agrupación principal de funcionalidades.',
                    example: 'Configuración, Organización, Talento Humano'
                },
                application: {
                    title: 'Aplicación',
                    description: 'Segundo nivel de la jerarquía. Representa una aplicación o módulo dentro de una categoría.',
                    example: 'Super Admin, Data Lake, Mi compañía, Personas Evaluaciones'
                },
                mainView: {
                    title: 'Vista Principal',
                    description: 'Tercer nivel de la jerarquía. Representa una vista o página principal dentro de una aplicación.',
                    example: 'Lista de Empleados, Formulario de Evaluación, Dashboard de Nómina'
                },
                subView: {
                    title: 'Sub-vista',
                    description: 'Cuarto nivel de la jerarquía. Representa una sub-vista o componente dentro de una vista principal.',
                    example: 'Editar Persona,Editar Sucursal, Editar Departamento'
                }
            },
            modalTitle: "",
            isEdit: false

        };
    },

  components: {
    draggable,
  },

  async mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    console.log("EL USUARIO ES" + this.usuario.rol.nivel);
    const personaId = this.$route.params.PersonaId;
    await this.obtenerPersonas(personaId);
    await this.fetchJsonData();
    console.log("data---------------------------", this.data);
  },

    methods: {
        // MÉTODOS PARA OBTENER Y GUARDAR DATOS DE LA API DE HELEXIUM //
        async fetchJsonData() {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "RegistrosJsonControlAccesos/GetLatestJson"
                });
                if (res && res.registroJson) {
                    try {
                        let cleanedJsonString = res.registroJson.replace(/\\"/g, '"').replace(/\\u([0-9a-fA-F]{4})/g, '\\u$1');
                        const parsedJson = JSON.parse(cleanedJsonString);
                        console.log("ESTE ES EL REGISTROOOOOOOOOOOOOOOOO" + parsedJson)
                        this.data = {
                            id: res.id,
                            fechaRegistro: res.fechaRegistro,
                            registroJson: parsedJson
                        };
                    } catch (parseError) {
                        this.data = null;
                    }
                } else {
                    this.data = null;
                }
            } catch (error) {
                this.data = null;
            }
        },

    async obtenerPersonas(personaId) {
      try {
        let res = await this.$store.getters.fetchGet({
          path: "Persona/GetPersonaById/" + personaId,
        });
        personaId = await res.json();
        console.log("informacion", personaId);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Por favor recarga la pagina",
          icon: "error",
          confirmButtonText: "Voy a recargar la pagina",
        });
        //this.Persona = {};
        console.log("informacion error", error);
      }
    },
    async saveData() {
      try {
        let mapa = JSON.stringify(this.data.registroJson);
        console.log("ESTE ES EL REGISTROOOOOOOOOOOOOOOOOMAPA" + mapa);
        // Escapar las comillas en la cadena para obtener el formato escapado

        const response = await this.$store.dispatch("hl_post", {
          path: `RegistrosJsonControlAccesos/CreateJson`,
          method: "POST",
          data: mapa,
        });

        if (response) {
          this.showSweetAlert(
            "Éxito",
            "Los permisos del Rol han sido actualizados correctamente.",
            "success"
          );
          this.fetchJsonData();
        } else {
          throw new Error("La respuesta del servidor no fue exitosa");
        }
      } catch (error) {
        console.error("Error al guardar los cambios:", error);
        this.showSweetAlert(
          "Error",
          "Hubo un problema al guardar los cambios. Por favor, intenta de nuevo.",
          "error"
        );
      }
    },

        // MÉTODOS PARA ORDENAR Y GESTIONAR INFORMACIÓN DE LA VISTA //
        cancelar() {
            this.$router.push('/dashboard');
        },
        checkMove: function (e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
        },
        showSweetAlert(title, text, icon) {
            Swal.fire({
                title: title,
                text: text,
                icon: icon,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
        },
        manipularTitulos(title, index) {

            return `${title.toLowerCase().replace(/[^a-z0-9]+/g, "-")}-${index}`;
        },
        manipularTitulosCategorias(title, index) {
            this.categoria = `${title.toLowerCase().replace(/[^a-z0-9]+/g, "-")}-${index}`

        },
        colapsar(subGroupIndex) {
            this.$set(this.colapsado, subGroupIndex, !this.colapsado[subGroupIndex]);
        },
        validarSuperAdmin(item) {
            if (!item) {
                return true;
            }
            if (this.usuario.rol.nivel === 4) {
                //console.log("Este usuario es Super Admin", this.usuario.rol.id);
                return true;
            }
            return false;
        },

        removeCategory(groupId) {
            Swal.fire({
                title: "¡Atención!",
                text: "Estas a punto de eliminar esta categoria del sistema, ¿estás seguro?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Estoy seguro",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // console.log("confirmado");
                    const index = this.data.registroJson.data.findIndex(item => item.groupId === groupId)
                    if (index !== -1) {
                        this.data.registroJson.data.splice(index, 1)
                    }
                }
            });
        },
        removeSubGroup(group, subGroup) {
            Swal.fire({
                title: "¡Atención!",
                text: "Estas a punto de eliminar esta vista del sistema, ¿estás seguro?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Estoy seguro",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // console.log("confirmado");
                    const index = group.subGrupo.findIndex(i => i.id === subGroup.id)
                    if (index !== -1) {
                        group.subGrupo.splice(index, 1)
                    }
                }
            });
        },
        removeItem(subGroup, item) {
            Swal.fire({
                title: "¡Atención!",
                text: "Estas a punto de eliminar esta vista del sistema, ¿estás seguro?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Estoy seguro",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // console.log("confirmado");
                    const index = subGroup.items.findIndex(i => i.id === item.id)
                    if (index !== -1) {
                        subGroup.items.splice(index, 1)
                    }
                }
            });
        },
        removeSubItem(item, subItem) {

            Swal.fire({
                title: "¡Atención!",
                text: "Estas a punto de eliminar esta sub vista del sistema, ¿estás seguro?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Estoy seguro",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // console.log("confirmado");
                    const index = item.subItems.findIndex(i => i.id === subItem.id)
                    if (index !== -1) {
                        item.subItems.splice(index, 1)
                    }
                }
            });


        },

        addCategory(newCategory, isEdit) {
            if (!isEdit) {
                Swal.fire({
                    title: "Agregar nueva categoría",
                    text: "¿Estás seguro de que deseas agregar esta categoría al sistema?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Añadir",
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (!newCategory || !newCategory.titulo || !newCategory.ruta || !newCategory.icono) {
                            Swal.fire("Error", "Faltan datos para añadir la categoría.", "error");
                            this.resetCategoryValues();
                            return;
                        }

                        const totalCategories = this.data.registroJson.data.length
                            ? Math.max(...this.data.registroJson.data.map(subItem => subItem.groupId)) + 1
                            : 1;
                        newCategory.groupId = totalCategories;

                        this.data.registroJson.data.push(newCategory);
                        this.resetCategoryValues();
                        Swal.fire("Éxito", "Categoría añadida correctamente.", "success");
                    }
                });
            } else {
                Swal.fire({
                    title: "Editar esta categoría",
                    text: "¿Estás seguro de que deseas editar esta categoría?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Editar",
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const categoryIndex = this.data.registroJson.data.findIndex(
                            (category) => category.groupId === newCategory.groupId
                        );
                        if (categoryIndex !== -1) {
                            // Actualizar los datos de la categoría
                            this.data.registroJson.data.splice(categoryIndex, 1, newCategory);
                            Swal.fire("Éxito", "Categoría actualizada correctamente.", "success");
                            this.resetCategoryValues();
                        } else {
                            Swal.fire(
                                "Error",
                                "No se encontró la categoría que deseas editar.",
                                "error"
                            );
                            this.resetCategoryValues();
                        }
                    }
                });
            }
        },

        addSubGroup(group, newSubGroup, isEdit) {
            if (!isEdit) {
                Swal.fire({
                    title: "Agregar nueva vista",
                    text: "¿Estás seguro de que deseas agregar esta vista al sistema?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Añadir",
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Verificar si faltan datos
                        if (!newSubGroup || !newSubGroup.titulo || !newSubGroup.ruta || !newSubGroup.icono || !newSubGroup.nivel) {
                            Swal.fire("Error", "Faltan datos para añadir la vista.", "error");
                            this.resetAplicationValues();
                            return;
                        }
                        // Calcular automáticamente un nuevo `groupId`
                        const totalCategories = group.subGrupo.length
                            ? Math.max(...group.subGrupo.map(subItem => subItem.id)) + 1
                            : 1;
                        newSubGroup.id = totalCategories
                        console.log(newSubGroup)
                        // Verificar si el grupo tiene subGrupo
                        if (!group.subGrupo) {
                            group.subGrupo = []; // Inicializar si no existe
                        }

                        // Verificar si ya existe un subGrupo con el mismo ID
                        const exists = group.subGrupo.some(sub => sub.id === newSubGroup.id);
                        if (exists) {
                            Swal.fire(
                                "Advertencia",
                                "Ya existe una vista con este ID en el grupo.",
                                "warning"
                            );
                            return;
                        }

                        // Añadir el nuevo subGrupo
                        group.subGrupo.push(newSubGroup);
                        this.resetAplicationValues();
                        Swal.fire("Éxito", "Vista añadida correctamente.", "success");
                    }
                });
            }
            else {
                const subGroupIndex = group.subGrupo.findIndex(
                    (subItem) => subItem.id === newSubGroup.id
                );
                if (subGroupIndex !== -1) {
                    // Actualizar el subgrupo
                    group.subGrupo.splice(subGroupIndex, 1, newSubGroup);
                    Swal.fire("Éxito", 'Vista editada correctamente.', "success");
                } else {
                    Swal.fire(
                        "Error",
                        "No se encontró la vista que deseas editar.",
                        "error"
                    );
                }
            }
        },
        addItem(subGroup, newItem, isEdit) {
            if (!isEdit) {
                Swal.fire({
                    title: "Agregar nuevo ítem",
                    text: "¿Estás seguro de que deseas agregar este ítem al subgrupo?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Añadir",
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Verificar que el nuevo ítem tiene los datos necesarios
                        if (!newItem || !newItem.titulo || !newItem.ruta || !newItem.icono || !newItem.nivel) {
                            Swal.fire("Error", "Faltan datos para añadir el ítem.", "error");
                            //console.log(newItem)
                            this.resetItemValues();
                            return;
                        }
                        console.log(newItem)
                        // Calcular automáticamente un nuevo `groupId`
                        const totalVistas = subGroup.items.length
                            ? Math.max(...subGroup.items.map(subItem => subItem.id)) + 1
                            : 1;
                        newItem.id = totalVistas

                        // Verificar si el ítem ya existe en el subgrupo
                        const exists = subGroup.items.some((item) => item.id === newItem.id);
                        if (exists) {
                            Swal.fire(
                                "Advertencia",
                                "Ya existe un ítem con este ID en el subgrupo.",
                                "warning"
                            );
                            return;
                        }

                        // Añadir el nuevo ítem al subgrupo
                        subGroup.items.push(newItem);
                        this.resetItemValues();
                        Swal.fire("Éxito", "Ítem añadido correctamente.", "success");
                    }
                });
            }
            else {
                const itemIndex = subGroup.items.findIndex((item) => item.id === newItem.id);
                if (itemIndex !== -1) {
                    // Actualizar el ítem
                    subGroup.items.splice(itemIndex, 1, newItem);
                    Swal.fire("Éxito", "Ítem editado correctamente.", "success");
                } else {
                    Swal.fire("Error", "No se encontró el ítem que deseas editar.", "error");
                }
            }
        },
        addSubItem(item, newSubItem, isEdit) {
            if (!isEdit) {
                Swal.fire({
                    title: "Agregar nueva sub vista",
                    text: "¿Estás seguro de que deseas agregar esta sub vista al sistema?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Añadir",
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    if (result.isConfirmed) {

                        // Validar que los datos del nuevo subItem sean correctos
                        if (!newSubItem || !newSubItem.titulo || !newSubItem.ruta || !newSubItem.icono) {
                            Swal.fire("Error", "Faltan datos para añadir la sub vista.", "error");
                            this.resetSubItemValues();
                            return;
                        }
                        //console.log("ESTOS SON LOS SUBITEEEMS" + item.subItems)

                        const totalSubVistas = item.subItems.length
                            ? Math.max(...item.subItems.map(subItem => subItem.id)) + 1
                            : 1;
                        newSubItem.id = totalSubVistas;
                        // Verificar si ya existe un subItem con el mismo ID en el item
                        const exists = item.subItems.some((subItem) => subItem.id === newSubItem.id);
                        if (exists) {
                            Swal.fire(
                                "Advertencia",
                                "Ya existe una sub vista con este ID en el sistema.",
                                "warning"
                            );
                            return;
                        }

                        // Añadir el nuevo subItem al item
                        item.subItems.push({ ...newSubItem });
                        this.resetSubItemValues();
                        Swal.fire("Éxito", "Sub vista añadida correctamente.", "success");
                    }
                });
            }
            else {
                const subItemIndex = item.subItems.findIndex((sub) => sub.id === newSubItem.id);
                if (subItemIndex !== -1) {
                    item.subItems.splice(subItemIndex, 1, { ...newSubItem });
                    Swal.fire("Éxito", 'Sub vista actualizada correctamente.', "success");
                } else {
                    Swal.fire("Error", "No se encontró la sub vista que deseas editar.", "error");
                }
            }
        },

        resetCategoryValues() {
            this.newCategory = {
                groupId: 0,
                titulo: '',
                ruta: '',
                icono: '',
                subGrupo: []
            }
        },
        resetAplicationValues() {
            this.newAplication = {
                id: 0,
                titulo: '',
                icono: '',
                ruta: '',
                nivel: '4',
                items: []
            }
        },
        resetItemValues() {
            this.newItem = {
                id: 0,
                titulo: '',
                icono: '',
                ruta: '',
                nivel: '4',
                superAdmin: null,
                subItems: null
            }
        },
        resetSubItemValues() {
            this.newSubItem = {
                id: 0,
                titulo: '',
                icono: '',
                ruta: '',
            }
        },
        selectCategory(groupId) {
            this.categoria = groupId
        },
        deselectCategory() {
            this.categoria = null
        },
        modalCategory(category = {}, attribute, subGroup = {}, subGroupAttribute, modalTitle, isEdit) {
            //LOS ATRIBUTOS SON LOS DEFINIDOS EN EL DATA
            this.resetCategoryValues()
            this.resetAplicationValues()
            this.resetItemValues()
            this.resetSubItemValues()

            this[attribute] = { ...category }
            this[subGroupAttribute] = { ...subGroup }
            this.modalTitle = modalTitle
            this.isEdit = isEdit



        }
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        isMobile() {
            return window.innerWidth < 768
        },
        selectedCategoryTitle() {
            const category = this.data.registroJson.data.find(group => group.groupId === this.categoria)
            return category ? category.titulo : ''
        },

        isSubItemsArray: {
            get() {
                // Devuelve `true` si subItems es un arreglo, de lo contrario `false`
                return Array.isArray(this.newItem.subItems);
            },
            set(value) {
                // Actualiza subItems en función del valor del checkbox
                this.newItem.subItems = value ? [] : null;
            },
        },


    },
    filters: {
        formatDate(date) {

            return date ? moment(date).locale("es").format('D MMM YYYY') : "No hay fecha registrada";
        },
        timeSince(dateString, timezone = "America/Bogota") {
            const dateInBogota = moment_timezone.tz(dateString, timezone);
            return dateInBogota.format("D MMM YYYY hh:mm A");
        },
    },
}


</script>
<style>
.vs-button__content {
    display: flex !important;
    align-items: baseline !important;
}

.vs-dialog__content {
    padding: 0 !important;

}


.vs-dialog__content.notFooter {
    margin-bottom: 0px !important;
    border-radius: 60px !important;
}
</style>
<style scoped>
.hierarchy-explanation {
    margin-bottom: 0px !important;
}

.permission-manager {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.layout {
    display: flex;
    gap: 20px;
}

.main-content {
    flex: 1;
}



.card-body {
  padding: 0px !important;
}

.permission-manager {
  max-width: 1200px;
  margin: 0 auto;
}

.selected-person-info {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
}

.b-tabs.card.vertical {
  border: none;
}

.tab-content {
  padding-left: 20px;
}

.list-group-item {
  transition: background-color 0.2s ease;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
}

.badge {
  font-size: 0.9em;
}

.mapa-acceso-container {
  max-height: 200px;
  overflow-y: auto;
  background-color: #2167ff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.mapa-acceso {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 10px;
  margin: 0;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.handle {
  cursor: pointer !important;
}

/* ESTILOS MENU DE GESTION DE VISTAS DE HELEXIUM */
.gestionar-layout {
  display: flex;
  height: 60vh;
  overflow: hidden;
}

.left-panel {
  width: 300px;
  overflow-y: auto;
  border-right: 1px solid #d1d1d1;
  transition: all 0.3s ease;
}

.right-panel {
  flex-grow: 1;
  padding: 5px 20px 20px 20px;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.category-item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.category-item:hover {
  background-color: #085dc55e;
}

.category-item.active {
  background-color: #085ec5;
}

.category-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #d1d1d142;
}

.no-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #888;
}

.handle {
  cursor: move;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.flip-list-move {
  transition: transform 0.5s;
}

@media (max-width: 767px) {
  .gestionar-layout {
    flex-direction: column;
  }

  .left-panel {
    width: 100%;
    height: 100vh;
  }

  .right-panel {
    width: 100%;
    height: 100vh;
  }

  .mobile-hidden {
    display: none;
  }

    .mobile-full {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background: white;
    }
    i, .h5{
        font-size: 0.7rem;
    }
}
</style>
